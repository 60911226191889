import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, Flex, Box, css } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import CookieConsent from 'react-cookie-consent';


export const Layout = ({ children, pageContext, location }) => (
  <ThemeProvider theme={theme}>
    <pageContextProvider.Provider value={{ pageContext, location }}>
      <Flex variant='layout.layout'>
        <Global styles={css(theme => theme.global)} />
        <CookieConsent
          location="center"
          enableDeclineButton="true"
          declineButtonText="Ablehnen"
          buttonText="Akzeptieren"
          overlay="true"
          cookieName="gatsby-gdpr-google-analytics">
            <h3>Ihre Privatsphäre ist uns wichtig</h3>
            Diese Website verwendet Cookies, um Ihnen ein besseres Internet-Erlebnis zu ermöglichen. Diese Technologien nutzen wir außerdem, um Ergebnisse zu messen, um zu verstehen, woher unsere Besucher kommen oder um unsere Website weiter zu entwickeln.
        </CookieConsent>
        <Header />
        <Box variant='layout.body'>{children}</Box>
        <Footer />
      </Flex>
    </pageContextProvider.Provider>
  </ThemeProvider>
)
