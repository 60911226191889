import React from 'react'
import { Container, Box, Flex, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import { FooterLogo } from './Footer.Logo'
import { FooterMenu } from './Footer.Menu'


const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [5, 6]
  }
}

export const Footer = () => (
  <Box sx={styles.wrapper}>
    <Container variant='compact' sx={styles.container}>
      <Flex variant='layout.footer'>
        <Box>
          <FooterLogo />
        </Box>
        <Box>
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            <a href="https://goo.gl/maps/cAxE77GipwywDPLz9" target="_blank">Hansastr. 15, 24118 Kiel</a>
          </Text>
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            <a href="tel:+494318890175">0431 88 90 175</a>
          </Text>
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            <a href="mailto:info@psychotherapie-hild.de">info@psychotherapie-hild.de</a>
          </Text>
        </Box>
        <FooterMenu />
      </Flex>
    </Container>
  </Box>
)
